import * as z from 'zod'
import isInChannel from 'utils/isInChannel'
import {
  CmsDownloadFile,
  CmsDownloadSection,
  CmsFeatureAlternatingFeature,
  CmsFeatureAlternatingSection,
  CmsFeatureOffsetFeature,
  CmsFeatureOffsetSection,
  CmsVideoSection,
  CmsVisualSpecsSection,
  Product,
  StrapiComponent,
  Variant,
} from 'framework/strapi/types'
import translate from 'utils/translate'
import { decode } from 'he'

export const zBreadCrumb = z.object({
  url: z.string(),
  name: z.string().transform((s) => decode(s)),
})

export type BreadCrumb = z.infer<typeof zBreadCrumb>

const zProductCertifications = z
  .array(z.string())
  .nullable()
  .transform((data) => data || [])

const zProductAtAGlance = z
  .array(
    z.object({
      text: z.string(),
    }),
  )
  .nullable()
  .transform((data) => data || [])

const zProductMedia = z
  .array(
    z.object({
      url: z.string().url(),
      thumbUrl: z.string().url().optional().nullable(),
    }),
  )
  .nullable()
  .transform((data) => data || [])

const zVariantImages = z
  .array(
    z.object({
      name: z.string(),
      caption: z.string().optional().nullable(),
    }),
  )
  .nullable()
  .transform((data) => data || [])

const zSimpleFeatures = z
  .array(z.object({ title: z.string(), text: z.string() }))
  .nullable()
  .transform((data) => data || [])

const zAdvancedFeatures = z
  .array(
    z.object({
      title: z.string(),
      text: z.string(),
      mediaUrl: z.string(),
      mediaMimeType: z.string(),
      url: z.string(),
      ctaText: z.string(),
    }),
  )
  .nullable()
  .transform((data) => data || [])

const zProductBundleDataItemVariant = z.object({
  id: z.string(),
  quantity: z.number(),
  selected: z.boolean(),
  channels: z.string(),
})

const zProductBundleDataItem = z.object({
  id: z.string(),
  title: z.record(z.string()).optional(),
  type: z.union([
    z.literal('variant'),
    z.literal('quantity'),
    z.literal('list'),
  ]),
  items: z.array(zProductBundleDataItemVariant),
})

export type ProductBundleDataItem = Omit<
  z.infer<typeof zProductBundleDataItem>,
  'title'
> & {
  title: string | undefined
}

const zProductBundle = z.object({
  includeOriginalVariant: z.boolean(),
  items: z.array(zProductBundleDataItem),
})

/**
 * File from enterprise, used for fields like Files, and FilesProductSheet
 */
const zFile = z.object({
  title: z.string(),
  url: z.string(),
})

const zSymbol = z.object({
  icon: z.string().url(),
  text: z.string(),
  icon_nr: z.number(),
})

export const mapVariantViewModel = (
  variant: Variant,
  locale: string,
  country: string,
) => {
  const { products, ...rest } = variant

  // Split name inte name and subtitle
  const [title, subtitle] = variant.name.split(' - ')

  const product = products?.[0]
  if (!product) throw new Error(`Variant ${variant.code} has no product`)

  // Get primary category
  const primaryCategory = product.categories?.find(
    (c) => c.code == product.mainCategoryId,
  )

  const isInChannelProduct = isInChannel({
    text: variant.availableOnline,
    locale,
    country,
    defaultIfEmpty: false, // set false if variant.availableOnline is empty
  })

  let availableOnlineAbsolute = isInChannelProduct

  // Category has the highest priority for available online
  if (availableOnlineAbsolute) {
    const allCategoriesAvailableOnline = product.categories?.every(
      (category) => {
        const availableOnlineCategory = category.availableOnline
        return isInChannel({
          text: availableOnlineCategory,
          locale,
          country,
        })
      },
    )
    availableOnlineAbsolute = allCategoriesAvailableOnline as boolean
  }

  const r = {
    ...rest,
    title,
    subtitle,
    availableOnline: availableOnlineAbsolute,
    variesBy: buildVariantOptionTree(product, variant, locale),
    variesByOptions: buildVariantOptions(product, variant, locale),
    isBundle: !!variant.bundleData,
    bundleData: mapBundleData(variant.bundleData, locale),
    allCodes: [variant.code],
    images: zVariantImages.parse(variant.images),
    product: {
      ...product,
      discontinued: isInChannel({
        text: product.discontinued,
        locale,
        country,
        defaultIfEmpty: false,
      }),
      certifications: zProductCertifications.parse(product.certifications),
      atAGlace: zProductAtAGlance.parse(product.atAGlance),
      productMedia: zProductMedia.parse(product.productMedia),
    },
    breadCrumbs: primaryCategory?.breadcrumbs
      ? zBreadCrumb.array().parse(primaryCategory.breadcrumbs)
      : [],
    sections: mapProductSections(variant, product),
    londonDynamicsConfig: variant.londonDynamicsConfig,
  }
  // Map bundle data if variant is a bundle
  if (r.bundleData) {
    for (const bundleDataItem of r.bundleData.items) {
      for (const variant of bundleDataItem.items) {
        r.allCodes.push(variant.id)
      }
    }
    // Sort the codes and remove duplicates
    r.allCodes = [...new Set(r.allCodes)].sort()
  }
  return r
}

export interface OptionTree {
  items: (OptionTreeNode | OptionTreeLeafNode)[]
  lables: string[]
  selected: string[]
}

interface OptionTreeNode {
  type: 'node'
  id: string
  name: string
  sortIndex: number | null
  items: (OptionTreeNode | OptionTreeLeafNode)[]
}

interface OptionTreeLeafNode {
  type: 'leaf'
  id: string
  name: string
  href: string
  code: string
  sortIndex: number | null
}

interface VariantOption {
  id: string
  name: string
}

// const exampleTree: OptionTree[] = [
//   {
//     lables: ['material', 'color', 'size'],
//     selected: ['fabric', 'blue', 'xxl'],
//     items: [
//       {
//         type: 'node',
//         name: 'fabric',
//         sortIndex: 1,
//         items: [
//           {
//             type: 'leaf',
//             name: 'xxl',
//             href: '/blue/small/xxl',
//             sortIndex: 1,
//           },
//           {
//             type: 'leaf',
//             name: 'xxl',
//             href: '/blue/small/xxl',
//             sortIndex: 1,
//           },
//         ],
//       },
//       {
//         type: 'node',
//         name: 'Size',
//         sortIndex: 2,
//         items: [],
//       },
//     ],
//   },
// ]

// Step 2: Build a tree from your product variants
function buildVariantOptionTree(
  product: Product,
  currentVariant: Variant,
  locale: string,
): OptionTree {
  if (!product.variants?.length)
    return {
      items: [],
      lables: [],
      selected: [],
    }

  const tree: OptionTree = {
    items: [],
    lables: [],
    selected: [],
  }

  // Set lables
  if (product.variesBy1) {
    tree.lables.push(product.variesBy1)
  }
  if (product.variesBy2) {
    tree.lables.push(product.variesBy2)
  }
  if (product.variesBy3) {
    tree.lables.push(product.variesBy3)
  }

  // Set selected
  if (currentVariant.variesBy1) {
    tree.selected.push(currentVariant.variesBy1)
  }
  if (currentVariant.variesBy2) {
    tree.selected.push(currentVariant.variesBy2)
  }
  if (currentVariant.variesBy3) {
    tree.selected.push(currentVariant.variesBy3)
  }

  for (const variant of product.variants) {
    // Variant must be available in the current channel
    const variantChannels = variant.channels ? variant.channels.split(',') : []
    if (!variantChannels.includes(locale)) {
      console.warn(`Variant ${variant.code} not avaible in locale ${locale}`)
      continue
    }

    // Variant must have a path
    if (!variant.path) {
      console.warn(`Variant ${variant.code} has no path`)
      continue
    }

    // If the variant varies by 1, add it to the tree
    if (!variant.variesBy1) continue

    // Check if variant is already in the tree
    let variesBy1 = tree.items.find((x) => x.name === variant.variesBy1)

    if (!variesBy1) {
      const isLeaf = !variant.variesBy2
      variesBy1 = isLeaf
        ? {
            type: 'leaf',
            id: variant.code,
            name: variant.variesBy1,
            href: variant.path,
            code: variant.code,
            sortIndex: variant.variesBy1_sortIndex,
          }
        : {
            type: 'node',
            id: variant.code,
            name: variant.variesBy1,
            items: [],
            sortIndex: variant.variesBy1_sortIndex,
          }
      tree.items.push(variesBy1)
      tree.items.sort((a, b) => a.sortIndex! - b.sortIndex!)
    }

    // If variant does not vary by 2, continue
    if (!variant.variesBy2) continue

    if (variesBy1.type === 'leaf') {
      console.warn(
        `Variant ${variant.code} varies by 2, but the tree is a leaf node`,
      )
      continue
    }

    let variesBy2 = variesBy1.items.find((x) => x.name === variant.variesBy2)

    if (!variesBy2) {
      const isLeaf = !variant.variesBy3

      variesBy2 = isLeaf
        ? {
            id: variant.code,
            type: 'leaf',
            name: variant.variesBy2,
            href: variant.path,
            code: variant.code,
            sortIndex: variant.variesBy2_sortIndex,
          }
        : {
            id: variant.code,
            type: 'node',
            name: variant.variesBy2,
            items: [],
            sortIndex: variant.variesBy2_sortIndex,
          }
      variesBy1.items.push(variesBy2)
      variesBy1.items.sort((a, b) => a.sortIndex! - b.sortIndex!)
    }

    // If the variant does not vary by 3, continue
    if (!variant.variesBy3) continue

    if (variesBy2.type === 'leaf') {
      console.warn(
        `Variant ${variant.code} varies by 3, but the tree is a leaf node`,
      )
      continue
    }

    let variesBy3 = variesBy2.items.find((x) => x.name === variant.variesBy3)

    if (!variesBy3) {
      variesBy3 = {
        type: 'leaf',
        id: variant.code,
        name: variant.variesBy3,
        sortIndex: variant.variesBy3_sortIndex,
        href: variant.path,
        code: variant.code,
      }
      variesBy2.items.push(variesBy3)
      variesBy2.items.sort((a, b) => a.sortIndex! - b.sortIndex!)
    }
  }
  return tree
}

function buildVariantOptions(
  product: Product,
  currentVariant: Variant,
  locale: string,
): string[] {
  if (!product.variants?.length) return [] as string[]

  const result: string[] = []
  const selected: string[] = []

  // Set selected
  if (currentVariant.variesBy1) {
    selected.push(currentVariant.variesBy1)
  }
  if (currentVariant.variesBy2) {
    selected.push(currentVariant.variesBy2)
  }
  if (currentVariant.variesBy3) {
    selected.push(currentVariant.variesBy3)
  }

  const v1: VariantOption[] = []
  const v2: VariantOption[] = []
  const v3: VariantOption[] = []

  for (const variant of product.variants) {
    const variantChannels = variant.channels ? variant.channels.split(',') : []
    if (!variantChannels.includes(locale)) continue
    if (!variant.path) continue
    if (!variant.variesBy1) continue

    v1.push({ id: variant.code, name: variant.variesBy1 })
    if (variant.variesBy2)
      v2.push({ id: variant.code, name: variant.variesBy2 })
    if (variant.variesBy3)
      v3.push({ id: variant.code, name: variant.variesBy3 })
  }

  let variantCodes = v1.map((v) => v.id)

  if (v3.length > 0) {
    const v3Codes = v3.filter((x) => x.name == selected[2]).map((v) => v.id)
    variantCodes = variantCodes.filter((x) => v3Codes.includes(x))

    const validOption1 = [
      ...new Set(
        v1.filter((x) => variantCodes.includes(x.id)).map((x) => x.name),
      ),
    ]
    result.push(...validOption1)
  }
  return result
}

export type VariantViewModel = ReturnType<typeof mapVariantViewModel>

function mapProductSections(variant: Variant, product: Product) {
  const components: StrapiComponent[] = []

  mapSymbols(product.symbols, components)

  mapSimpleFeatures(
    variant.simpleFeaturesContent ?? product.simpleFeaturesContent,
    components,
  )

  mapSpecifications(variant.specifications, components)

  mapAdvancedFeatures(product.advancedFeatures, components)

  mapYoutubeVideo(product, components)

  mapFiles(variant, product, components)

  return components
}

const mapSpecifications = (specs: unknown, components: StrapiComponent[]) => {
  // Specifications:
  const specifications = z.record(z.string()).nullable().optional().parse(specs)

  if (specifications) {
    // Specifications:
    const specificationComponent: CmsFeatureOffsetSection = {
      channel: null,
      id: 'productpage.specification',
      __component: 'cms.feature-offset-section',
      settings: {
        id: 'productpage.specification.settings',
        __component: 'common.section-settings',
        tw_spacing: '',
        colorTheme: 'Dark',
        height: 'Default',
        anchor: '',
        container: 'Default',
      },
      background: {
        id: 'productpage.specification.background',
        __component: 'common.background',
        layout: null,
        captionText: null,
        layers: [
          {
            id: 'productpage.specification.background.layer',
            __component: 'common.background-layer',
            tw_value: 'bg-primary-dark',
            tw_overlay: null,
            image: null,
            channel: null,
            image_canto: null,
          },
        ],
        tw_captionPlacement: null,
        tw_paddingTop: null,
        tw_paddingBottom: null,
      },
      collapsed: false,
      layout: 'Compact',
      heading: translate('specifications'),
      buttonTextExpanded: translate('showMore'),
      buttonTextCollapsed: translate('hide'),
      features: mapSpecificationFeatures(specifications),
    }
    components.push(specificationComponent)
  }
}

const mapSpecificationFeatures = (
  specs: Record<string, string>,
): CmsFeatureOffsetFeature[] => {
  const results: CmsFeatureOffsetFeature[] = []
  const warrantyTextHeading = translate('warrantyText')
  const lowerCaseWarrantyTextHeading = warrantyTextHeading.toLowerCase()
  const warrantyTextKey = Object.keys(specs).find(
    key => key.toLowerCase() === lowerCaseWarrantyTextHeading
  )
  const warrantyText = warrantyTextKey ? specs[warrantyTextKey] : undefined

  const specsArray = Object.entries(specs)
  for (let i = 0; i < specsArray.length; i++) {
    if (!specsArray[i]) continue
    const [heading, text] = specsArray[i]!
    if (heading.toLowerCase() === lowerCaseWarrantyTextHeading) {
      continue
    }
    const r: CmsFeatureOffsetFeature = {
      channel: null,
      id: `productpage.specification.item.${i}`,
      __component: 'cms.feature-offset-section',
      heading,
      text,
      icon: null,
      info: null,
      cta: null,
    }

    if (heading == translate('warranty')) {
      if (warrantyText) {
        const isLink = warrantyText?.startsWith('https')
        if (isLink) {
          r.cta = {
            id: 'productpage.specification.cta',
            __component: 'common.link',
            title: 'media link',
            url: {
              id: 'productpage.specification.cta.url',
              type: 'link',
              src: warrantyText,
            },
          }
        } else {
          r.info = warrantyText
        }
      }
    }
    results.push(r)
  }
  return results
}

const mapSymbols = (symbolsUnknown: unknown, components: StrapiComponent[]) => {
  const symbols = zSymbol.array().parse(symbolsUnknown)

  if (symbols.length === 0) {
    return
  }

  const r: CmsVisualSpecsSection = {
    channel: null,
    id: 'productpage.visual-specs-section',
    __component: 'cms.visual-specs-section',
    settings: {
      id: 'productpage.visual-specs-section.settings',
      __component: 'common.section-settings',
      tw_spacing: '',
      colorTheme: 'Dark',
      height: 'Default',
      anchor: 'productFeatures',
      container: 'Default',
    },
    background: {
      id: 'productpage.visual-specs-section.background',
      __component: 'common.background',
      layout: null,
      captionText: null,
      layers: [
        {
          id: 'productpage.specification.background.layer',
          __component: 'common.background-layer',
          tw_value: 'bg-primary-dark',
          tw_overlay: null,
          image: null,
          channel: null,
          image_canto: null,
        },
      ],
      tw_captionPlacement: null,
      tw_paddingTop: null,
      tw_paddingBottom: null,
    },
    gallery: null,
    specs: symbols.map((symbol, index: number) => {
      return {
        channel: null,
        id: `productpage.visual-specs.specs.${index}`,
        __component: 'cms.visual-specs-spec',
        icon: symbol.icon_nr.toString(),
        description: symbol.text,
      }
    }),
  }
  components.push(r)
}

const mapSimpleFeatures = (
  simpleFeaturesUnknown: unknown,
  components: StrapiComponent[],
) => {
  // Simple features
  const simpleFeatures = zSimpleFeatures.parse(simpleFeaturesUnknown)

  if (simpleFeatures.length === 0) {
    return
  }
  const r: CmsFeatureOffsetSection = {
    channel: null,
    id: 'productpage.simpleFeatures',
    __component: 'cms.feature-offset-section',
    settings: {
      id: 'productpage.simpleFeatures.settings',
      __component: 'common.section-settings',
      tw_spacing: '',
      colorTheme: 'Dark',
      height: 'Default',
      anchor: 'productFeatures',
      container: 'Default',
    },
    background: {
      id: 'productpage.simpleFeatures.background',
      __component: 'common.background',
      layout: null,
      captionText: translate('raiseTheBar'),
      layers: [
        {
          id: -18603,
          __component: 'common.background-layer',
          tw_value: 'bg-primary',
          tw_overlay: null,
          image: null,
          channel: null,
          image_canto: null,
        },
      ],
      tw_captionPlacement: 'lg:bottom-left',
      tw_paddingTop: null,
      tw_paddingBottom: null,
    },
    heading: translate('features'),
    layout: 'Default',
    collapsed: false,
    buttonTextExpanded: translate('showMore'),
    buttonTextCollapsed: translate('hide'),
    features: simpleFeatures.map(({ text, title }, index: number) => {
      return {
        channel: null,
        id: `productpage.simpleFeatures.feature.${index}`,
        __component: 'cms.feature-offset-section',
        heading: title,
        text,
        icon: null,
        info: null,
        cta: null,
      }
    }),
  }
  components.push(r)
}

const ensureCtaText = (feature: Record<string, unknown>) => {
  if (typeof feature.ctaText === 'undefined') {
    feature.ctaText = ''
  }
  return feature
}

const mapAdvancedFeatures = (
  advancedFeaturesUnknown: unknown,
  components: StrapiComponent[],
) => {
  const featureObjects = advancedFeaturesUnknown as Record<string, unknown>[]
  const processedFeatures = featureObjects.map((feature) =>
    ensureCtaText(feature),
  )
  const advancedFeatures = zAdvancedFeatures.parse(processedFeatures)

  const r: CmsFeatureAlternatingSection = {
    channel: null,
    id: 'productpage.advancedFeatures',
    __component: 'cms.feature-alternating-section',
    settings: {
      id: 'productpage.advancedFeatures.settings',
      __component: 'common.section-settings',
      colorTheme: 'Default',
      container: 'Default',
      height: 'Default',
      anchor: '',
      tw_spacing: '',
    },
    background: {
      id: 'productpage.advancedFeatures.background',
      __component: 'common.background',
      captionText: null,
      tw_captionPlacement: null,
      tw_paddingTop: null,
      tw_paddingBottom: null,
      layout: null,
      layers: null,
    },
    features: advancedFeatures.reduce<CmsFeatureAlternatingFeature[]>(
      (a, feature, index) => {
        a.push({
          id: `productpage.advancedFeatures.item.${index}`,
          __component: 'cms.feature-alternating-feature',
          heading: feature.title,
          text: feature.text,
          gallery: feature.mediaUrl
            ? {
                channel: null,
                id: `productpage.advancedFeatures.item.${index}.gallery`,
                __component: 'cms.gallery',
                layout: 'Default',
                zoom: false,
                tw_aspectRatio: 'aspect-w-3 aspect-h-2',
                tw_lightBoxAspectRatio: 'aspect-w-3 aspect-h-2',
                captionStyle: 'Default',
                items: [
                  {
                    channel: null,
                    id: `productpage.advancedFeatures.item.${index}.gallery.items.0`,
                    __component: 'cms.gallery-item',
                    caption: '',
                    asset: {
                      id: -19865 - index,
                      __component: 'cms.gallery',
                      name: feature.title,
                      alternativeText: feature.text,
                      caption: '',
                      formats: {
                        large: {
                          name: feature.title,
                          url: feature.mediaUrl,
                          mime: feature.mediaMimeType,
                          width: 0,
                          height: 0,
                        },
                      },
                      url: feature.mediaUrl,
                      mime: feature.mediaMimeType,
                      width: 0,
                      height: 0,
                    },
                    externalVideo: null,
                    thumbnail: null,
                    thumbnail_canto: null,
                    asset_canto: null,
                  },
                ],
              }
            : null,
          overline: null,
          ctas: feature.url
            ? [
                {
                  id: -182733,
                  __component: 'common.link',
                  title: feature.ctaText
                    ? feature.ctaText
                    : translate('showMore'),
                  url: {
                    id: -13382732,
                    type: 'external',
                    src: feature.url,
                  },
                },
              ]
            : null,
          channel: null,
          html: null,
          galleryPlacement: index % 2 === 0 ? 'Right' : 'Left',
        })
        return a
      },
      [],
    ),
  }
  components.push(r)
}
function mapFiles(
  variant: Variant,
  product: Product,
  components: StrapiComponent[],
) {
  const filesProductSheetVariant =
    z.array(zFile).nullable().parse(variant.filesProductSheetVariant) ?? []

  // const filesProductSheet =
  //   z.array(zFile).nullable().parse(product.filesProductSheet) ?? []

  const files = z.array(zFile).nullable().parse(product.files) ?? []

  // Merge all files and remove duplicates
  const allFiles = [...filesProductSheetVariant, ...files].reduce(
    (a, file) => {
      const exists = a.find((f) => f.url === file.url)
      if (!exists) {
        a.push(file)
      }
      return a
    },
    [] as z.infer<typeof zFile>[],
  )

  if (allFiles.length === 0) {
    return
  }

  const r: CmsDownloadSection = {
    channel: null,
    id: 'productpage.downloadablesComponent',
    __component: 'cms.download-section',
    settings: {
      id: 'productpage.downloadablesComponent.settings',
      __component: 'common.section-settings',
      colorTheme: 'Default',
      container: 'Default',
      height: 'Default',
      anchor: '',
      tw_spacing: '',
    },
    background: {
      id: 'productpage.downloadablesComponent.background',
      __component: 'common.background',
      captionText: null,
      tw_captionPlacement: null,
      tw_paddingTop: null,
      tw_paddingBottom: null,
      layout: null,
      layers: null,
    },
    heading: translate('downloads'),
    text: null,
    files: allFiles.reduce<CmsDownloadFile[]>((a, file, index) => {
      a.push({
        id: `productpage.downloadablesComponent.files.${index}`,
        __component: 'cms.download-file',
        url: file.url,
        title: file.title,
        channel: null,
      })
      return a
    }, []),
  }
  components.push(r)
}

export const mapYoutubeVideo = (
  product: Product,
  components: StrapiComponent[],
) => {
  const videoComponent: CmsVideoSection | null = !product.youtubeVideo
    ? null
    : {
        channel: null,
        id: 'productpage.videosComponent',
        __component: 'cms.video-section',
        settings: {
          id: 'productpage.videosComponent.settings',
          __component: 'common.section-settings',
          colorTheme: 'Default',
          container: 'Default',
          height: 'Default',
          anchor: '',
          tw_spacing: '',
        },
        videos: [
          {
            channel: null,
            id: 'productpage.videosComponent.videos.0',
            __component: 'cms.video-item',
            videoId: product.youtubeVideo,
          },
        ],
      }
  if (!!videoComponent) {
    components.push(videoComponent)
  }
}

function mapBundleData(bundleData: unknown, locale: string) {
  const parsed = zProductBundle.nullable().parse(bundleData)
  if (!parsed) return null

  // Get lang from locale. Ie. en-us -> en
  const lang = locale.split('-')[0]
  return {
    ...parsed,
    items: parsed.items.map((item) => {
      // Try to find the right title from the title object
      let title: string | undefined
      if (item.title) {
        for (const key in item.title) {
          // Get lang from locale. Ie. en-us -> en
          if (key.split('-')[0] === lang) {
            title = item.title[key]
            break
          }
        }
      }
      return {
        ...item,
        title,
      }
    }),
  }
}
